import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import {firebaseConfig} from "../firebaseConfig";

firebase.initializeApp(firebaseConfig)
firebase.analytics();

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storageRef = firebase.storage().ref();

// collection references
const usersCollection = db.collection('users')
const configurationsCollection = db.collection('configuration')
const partnersCollection = db.collection('partners')
const downloadsCollection = db.collection('downloads')
const galleriesCollection = db.collection('galleries')
const headersCollection = db.collection('headers')
const blogsCollection = db.collection('blogs')
const blogsFilesCollection = db.collection('blogs_files')
const textPagesCollection = db.collection('textpages')
const newsletterCollection = db.collection('newsletter')
const notificationUsersCollection = db.collection('notification_users')

export {
    db,
    auth,
    storageRef,
    configurationsCollection,
    usersCollection,
    partnersCollection,
    downloadsCollection,
    galleriesCollection,
    headersCollection,
    blogsCollection,
    textPagesCollection,
    newsletterCollection,
    blogsFilesCollection,
    notificationUsersCollection
}