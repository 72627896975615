<template>
  <div class="main-content bg-default height-100 whole-screen">
    <!-- Navbar -->
    <!-- Header -->
    <div class="header bg-gradient-orange py-7 py-lg-8 ">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6 text-center">
              <h1 class="text-white">Welcome!</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5 mb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
    <footer class="py-5">
      <div class="container">
        <div class="row align-items-center ">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-left text-muted">
              &copy; {{ year }}
              <router-link to="/" class="font-weight-bold ml-1">Dignest
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import {SlideYUpTransition} from 'vue2-transitions'

export default {
  name: 'auth-layout',
  components: {
    SlideYUpTransition
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false
    }
  }
}
</script>
<style>
.whole-screen {
  min-height: 100vh;
}
</style>
