<template>
  <base-nav class="navbar-top navbar-dark"
            id="navbar-main"
            :show-toggle-button="false"
            expand>
    <form class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
    </form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div class="media align-items-center" slot="title">
            <i class="ni ni-align-center"></i>
            <div class="media-body ml-3 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">
                {{ userProfile.firstName }} {{ userProfile.lastName }}
              </span>
            </div>
          </div>
          <template>
            <a @click="logout()" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: ''
    };
  },
  computed: {
    ...mapState(['userProfile'])
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>
