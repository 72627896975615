import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/admin/layout/DashboardLayout'
import AuthLayout from '@/admin/layout/AuthLayout'
import Content from "@/admin/layout/Content";
import {auth} from './firebase'

Vue.use(Router)

const router = new Router({
    linkExactActiveClass: "active",
    routes: [
        {
            path: "/",
            redirect: "home",
            component: () => import("./site/layouts/default.vue"),
            children: [
                {
                    path: "/",
                    component: () => import("./site/pages/index.vue"),
                },
                {
                    path: "/team-of-trainees-from-montenegro",
                    component: () => import("./site/pages/team-members"), // Done
                },
                {
                    path: "/newsletters/:slug",
                    component: () => import("./site/pages/newsletter"), // Done
                },
                {
                    path: "/participants",
                    component: () => import("./site/pages/services-list.vue"), // Done
                },
                {
                    path: "/activities",
                    component: () => import("./site/pages/activities.vue"), // Done
                },
                {
                    path: "/blogs",
                    component: () => import("./site/pages/blog/blog-grid-modern.vue"),
                },
                {
                    path: "/blogs/:slug",
                    component: () => import("./site/pages/blog/blog-details-modern-layout.vue"),
                },
                {
                    path: "/blogs/tag/:tag",
                    component: () => import("./site/pages/blog/blog-tags.vue"),
                },
                {
                    path: "/downloads",
                    component: () => import("./site/pages/element/dividers.vue"), // Done
                },
                {
                    path: "/working-packages",
                    component: () => import("./site/pages/working-packages.vue"),
                },
                {
                    path: "/coordination-and-management",
                    component: () => import("./site/pages/coordination-and-management.vue"),
                },
                {
                    path: "/page/:slug",
                    component: () => import("./site/pages/text-page.vue"),
                },
                {
                    path: "/galleries",
                    component: () => import("./site/layouts/default.vue"),// Done
                    children: [
                        {
                            path: "/",
                            component: () => import("./site/pages/portfolio/gallery.vue"), // Done
                        },
                        {
                            path: "g/:id",
                            component: () => import("./site/pages/portfolio/singleGallery.vue")// Done
                        }]
                }
            ]
        },
        {
            path: "/login",
            redirect: "login",
            component: AuthLayout,
            children: [
                {
                    path: "/login",
                    name: "login",
                    component: () => import("./admin/views/Login.vue"),
                },
            ]
        },
        {
            path: "/admin",
            redirect: "dashboard",
            component: DashboardLayout,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: 'dashboard',
                    component: () => import('./admin/views/Dashboard.vue')
                },
                {
                    path: "header",
                    component: Content,
                    children: [
                        {
                            path: "/",
                            component: () => import("./admin/views/Header/List.vue"),
                        }
                    ]
                },
                {
                    path: "partners",
                    component: Content,
                    children: [
                        {
                            path: "/",
                            component: () => import("./admin/views/Partners/List.vue"),
                        },
                        {
                            path: "create",
                            component: () => import("./admin/views/Partners/Create.vue"),
                        },
                        {
                            path: "edit/:id",
                            component: () => import("./admin/views/Partners/Edit.vue"),
                        }
                    ]
                },
                {
                    path: "downloads",
                    component: Content,
                    children: [
                        {
                            path: "/",
                            component: () => import("./admin/views/Downloads/List.vue"),
                        },
                        {
                            path: "create",
                            component: () => import("./admin/views/Downloads/Create.vue"),
                        },
                        {
                            path: "edit/:id",
                            component: () => import("./admin/views/Downloads/Edit.vue"),
                        }
                    ]
                },
                {
                    path: "galleries",
                    component: Content,
                    children: [
                        {
                            path: "/",
                            component: () => import("./admin/views/Galleries/List.vue"),
                        },
                        {
                            path: "create",
                            component: () => import("./admin/views/Galleries/Create.vue"),
                        },
                        {
                            path: "edit/:id",
                            component: () => import("./admin/views/Galleries/Edit.vue"),
                        }
                    ]
                },
                {
                    path: "headers",
                    component: Content,
                    children: [
                        {
                            path: "/",
                            component: () => import("./admin/views/Headers/List.vue"),
                        },
                        {
                            path: "create",
                            component: () => import("./admin/views/Headers/Create.vue"),
                        },
                        {
                            path: "edit/:id",
                            component: () => import("./admin/views/Headers/Edit.vue"),
                        }
                    ]
                },
                {
                    path: "blogs",
                    component: Content,
                    children: [
                        {
                            path: "/",
                            component: () => import("./admin/views/Blogs/List.vue"),
                        },
                        {
                            path: "create",
                            component: () => import("./admin/views/Blogs/Create.vue"),
                        },
                        {
                            path: "edit/:id",
                            component: () => import("./admin/views/Blogs/Edit.vue"),
                        }
                    ]
                },
                {
                    path: "downloads",
                    component: Content,
                    children: [
                        {
                            path: "/",
                            component: () => import("./admin/views/Downloads/List.vue"),
                        },
                        {
                            path: "create",
                            component: () => import("./admin/views/Downloads/Create.vue"),
                        },
                        {
                            path: "edit/:id",
                            component: () => import("./admin/views/Downloads/Edit.vue"),
                        },
                    ],
                },
                {
                    path: "textpages",
                    component: Content,
                    children: [
                        {
                            path: "/",
                            component: () => import("./admin/views/TextPages/List.vue"),
                        },
                        {
                            path: "create",
                            component: () => import("./admin/views/TextPages/Create.vue"),
                        },
                        {
                            path: "edit/:id",
                            component: () => import("./admin/views/TextPages/Edit.vue"),
                        }
                    ]
                },
                {
                    path: "newsletter",
                    component: Content,
                    children: [
                        {
                            path: "/",
                            component: () => import("./admin/views/Newsletter/List.vue"),
                        },
                        {
                            path: "create",
                            component: () => import("./admin/views/Newsletter/Create.vue"),
                        },
                        {
                            path: "edit/:id",
                            component: () => import("./admin/views/Newsletter/Edit.vue"),
                        }
                    ]
                },
                {
                    path: "notification-users",
                    component: () => import("./admin/views/NotifyUsers/List.vue")
                },
            ]
        }
    ]
});

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    if (requiresAuth && !auth.currentUser) {
        next('/login')
    } else {
        next()
    }
})

const DEFAULT_TITLE = 'Dignest';
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router