<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
        :background-color="sidebarBackground"
        short-title="Argon"
        title="Argon"
    >
      <template slot="links">
        <sidebar-item :link="{name: 'Home',icon: 'ni ni-tv-2 text-primary',path: '/admin/dashboard'}"/>
        <sidebar-item :link="{name: 'Header', icon: 'ni ni-image text-blue', path: '/admin/header'}"/>
        <sidebar-item :link="{name: 'Partners', icon: 'ni ni-world text-green', path: '/admin/partners'}"/>
        <sidebar-item :link="{name: 'Text Pages', icon: 'ni ni-collection text-orange', path: '/admin/textpages'}"/>
        <sidebar-item :link="{name: 'Blogs', icon: 'ni ni-single-copy-04 text-blue', path: '/admin/blogs'}"/>
        <sidebar-item :link="{name: 'Galleries', icon: 'ni ni-album-2 text-gray', path: '/admin/galleries'}"/>
        <sidebar-item :link="{name: 'Downloads', icon: 'ni ni-cloud-download-95 text-orange', path: '/admin/downloads'}"/>
        <sidebar-item :link="{name: 'Notification Users', icon: 'ni ni-notification-70 text-blue', path: '/admin/notification-users'}"/>
        <sidebar-item :link="{name: 'Newsletter', icon: 'ni ni-notification-70 text-blue', path: '/admin/newsletter'}"/>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import {FadeTransition} from 'vue2-transitions';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition
  },
  data() {
    return {
      sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
<style lang="scss">
</style>
