import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import './registerServiceWorker';
import ArgonDashboard from './admin/plugins/argon-dashboard';
import moment from 'moment';
import BootstrapVue from "bootstrap-vue";
import {firestorePlugin} from "vuefire";
import ToggleButton from "vue-js-toggle-button";
import wysiwyg from "vue-wysiwyg";
import 'vue-wysiwyg/dist/vueWysiwyg';
import Paginate from "vuejs-paginate";
import VueSmoothScroll from 'vue2-smooth-scroll'
import Swiper, { Pagination, Autoplay } from "swiper";
Swiper.use([Pagination, Autoplay]);


Vue.use(VueSmoothScroll, {
    duration: 400,
    updateHistory: false,
})
Vue.component("paginate", Paginate);
Vue.prototype.moment = moment
Vue.use(firestorePlugin);
Vue.use(BootstrapVue);
Vue.use(ToggleButton);
Vue.use(firestorePlugin);
import {auth} from './firebase';

Vue.use(ArgonDashboard)
Vue.use(wysiwyg, {
    forcePlainTextOnPaste: true,
    maxHeight: "650px",
    hideModules: {
        "table": true,
        "code": true,
        "removeFormat": true,
        "justifyLeft": true,
        "justifyCenter": true,
        "justifyRight": true
    }
});
Vue.config.productionTip = false
Vue.filter('str_limit', function (value, size) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + '...';
});
let app
auth.onAuthStateChanged(user => {
    if (!app) {
        app = new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
    }

    if (user) {
        store.dispatch('fetchUser', user)
    }
})






