import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from './firebase'
import router from './router'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userProfile: {
            uid: null
        },
        login: {
            error: {
                message: null
            }
        }
    },
    mutations: {
        setUserProfile(state, val) {
            state.userProfile = val.userProfile
            state.userProfile.uid = val.userId
        },
        getLoginErrorMessage(state, val) {
            state.login.error.message = val.message;
        }
    },
    actions: {
        async login({commit}, form) {
            await fb.auth.signInWithEmailAndPassword(form.email, form.password).then(() => {
            }).catch((error) => {
                commit('getLoginErrorMessage', error);
            });
        },
        async logout({commit}) {
            await fb.auth.signOut()
            commit('setUserProfile', {userProfile: {}, userId: null});
            router.push('/login')
        },
        async fetchUserProfile({commit}, user) {
            // fetch user profile
            const userProfile = await fb.usersCollection.doc(user.uid).get()

            // set user profile in state
            commit('setUserProfile', {userProfile: userProfile.data(), userId: user.uid})
            // change route to dashboard
            router.push('/admin/dashboard')
        },
        async fetchUser({commit}, user) {
            const userProfile = await fb.usersCollection.doc(user.uid).get()
            commit('setUserProfile', {userProfile: userProfile.data(), userId: user.uid})
        }
    }
})
  
